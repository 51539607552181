import React from 'react'
import {
  CFView,
  CFText,
  CFImage,
  CFLink,
  DefaultScreen,
  MobileScreen,
} from 'components'
import Header from './Header'
import OrderPickupButton from './OrderPickupButton'
import { hero, logo, mobileHero, viewMenu, menu, reserveButton } from 'images'

export default () => {
  return (
    <CFView>
      <MobileScreen>
        <CFView column center>
          <CFImage src={logo} alt="Logo" w="250px" mv="15px" />
          <CFImage src={mobileHero} alt="Logo" w="90%" mv="10px" />
          <CFView mv="10px" pulsate>
            <OrderPickupButton />
          </CFView>
          <a href={'tel:+6047320112'}>
            <CFView hover mb="10px">
              <CFImage
                w="100%"
                src={reserveButton}
                alt="Order Pickup Button"
                maxWidth="260px"
              />
            </CFView>
          </a>
          {/* <a href={menu} target="_blank">
            <CFView hover mb="15px">
              <CFImage
                w="100%"
                src={viewMenu}
                alt="Order Pickup Button"
                maxWidth="260px"
              />
            </CFView>
          </a> */}
        </CFView>
      </MobileScreen>
      <DefaultScreen>
        <CFView w="100%" zIndex={90} column justifyBetween alignCenter>
          <CFImage src={logo} alt="Logo" w="220px" mv="10px" />
          <CFImage src={hero} alt="Logo" w="90%" mv="10px" />
          <CFView row center mv="15px">
            <CFView pulsate>
              <OrderPickupButton />
            </CFView>
            <a href={'tel:+6047320112'}>
              <CFView hover ml="20px">
                <CFImage
                  w="100%"
                  src={reserveButton}
                  alt="Order Pickup Button"
                  maxWidth="260px"
                />
              </CFView>
            </a>
            {/* <a href={menu} target="_blank">
              <CFView hover ml="20px">
                <CFImage
                  w="100%"
                  src={viewMenu}
                  alt="Order Pickup Button"
                  maxWidth="260px"
                />
              </CFView>
            </a> */}
          </CFView>
        </CFView>
      </DefaultScreen>
    </CFView>
  )
}
